<template>
  <b-overlay :show="formShow" rounded="sm" no-fade>
    <b-row v-if="formShow == true">
      <b-col cols="12">
        <center>Loading...</center>
      </b-col>
    </b-row>

    <b-row v-else class="invoice-preview">
      <b-col cols="12" xl="10" md="9" class="mt-1">
        <b-card no-body class="invoice-preview-card table-responsive p-1">
          <span style="padding:10px;"
            ><strong>Quote Number :</strong> #{{ Number($route.params.id) }}
            <strong class="ml-5">Quote Date :</strong>
            {{
              customerDetailsData.quoteDate != null
                ? customerDetailsData.quoteDate
                : "N/A"
            }}
          </span>

          <table class="table table-bordered">
            <thead class="thead-dark">
              <tr>
                <th scope="col" colspan="12">
                  Customer Details
                </th>
              </tr>
            </thead>
            <tbody class="text-center">
              <tr class="textvalue">
                <td colspan="4" class="bg-light-secondary">
                  {{
                    customerDetailsData.firstName != null
                      ? customerDetailsData.firstName
                      : "N/A"
                  }}
                </td>
                <td colspan="4" class="bg-light-secondary">
                  {{
                    customerDetailsData.lastName != null
                      ? customerDetailsData.lastName
                      : "N/A"
                  }}
                </td>

                <td colspan="4" class="bg-light-secondary">
                  {{
                    customerDetailsData.email != null
                      ? customerDetailsData.email
                      : "N/A"
                  }}
                </td>
              </tr>
              <tr class="title">
                <th colspan="4" class="noborderright">First Name</th>
                <th colspan="4" class="noborderright">
                  Last Name
                </th>
                <th colspan="4">Email</th>
              </tr>

              <tr class="textvalue">
                <td colspan="4" class="bg-light-secondary">
                  {{
                    customerDetailsData.organisation != null
                      ? customerDetailsData.organisation
                      : "N/A"
                  }}
                </td>
                <td colspan="4" class="bg-light-secondary">
                  {{
                    customerDetailsData.phoneNumber != null
                      ? customerDetailsData.phoneNumber
                      : "N/A"
                  }}
                </td>

                <td colspan="4" class="bg-light-secondary">
                  {{
                    customerDetailsData.billingAdress != null
                      ? customerDetailsData.billingAdress
                      : "N/A"
                  }}
                </td>
              </tr>
              <tr class="title">
                <th colspan="4" class="noborderright">Organisation</th>
                <th colspan="4" class="noborderright">
                  Phone Number
                </th>
                <th colspan="4">Billing Price</th>
              </tr>

              <tr>
                <th colspan="4">Shipping Adress</th>
                <td colspan="8" class="bg-light-secondary">
                  {{
                    customerDetailsData.shippingAdress != null
                      ? customerDetailsData.shippingAdress
                      : "N/A"
                  }}
                </td>
              </tr>
            </tbody>
          </table>
          <table class="table table-bordered mt-2">
            <thead class="thead-dark">
              <tr>
                <th scope="col" colspan="12">
                  Contract Details
                </th>
              </tr>
            </thead>
            <tbody class="text-center">
              <tr class="title">
                <th colspan="2" class="noborderright">Customer Name</th>
                <th colspan="2" class="noborderright">Course Abbreviation</th>
                <th colspan="2" class="noborderright">
                  Course Delivery Option Code
                </th>
                <th colspan="2" class="noborderright">Date</th>
                <th colspan="2" class="noborderright">Participants</th>
                <th colspan="2" class="noborderright">Price</th>
              </tr>
              <tr
                class="textvalue"
                v-for="(contract, index) in contractDetailsData"
                :key="index"
              >
                <td colspan="2">
                  {{
                    contract.customerName != null
                      ? contract.customerName
                      : "N/A"
                  }}
                </td>
                <td colspan="2">
                  {{
                    contract.courseAbbreviation != null
                      ? contract.courseAbbreviation
                      : "N/A"
                  }}
                </td>
                <td colspan="2">
                  {{
                    contract.deliveryOPCode != null
                      ? contract.deliveryOPCode
                      : "N/A"
                  }}
                </td>
                <td colspan="2">
                  {{ contract.date != null ? contract.date : "N/A" }}
                </td>
                <td colspan="2">
                  {{
                    contract.participant != null ? contract.participant : "N/A"
                  }}
                </td>
                <td colspan="2">
                  {{ contract.price != null ? contract.price : "N/A" }}
                </td>
              </tr>
              <br /><br />
              <tr class="title">
                <td
                  colspan="8"
                  class="text-right mt-5"
                  style="border-top:hidden; border-left:hidden; border-bottom:hidden;"
                >
                  Total Contract Price
                </td>

                <td colspan="4" v-if="totalContractPrice > 0">
                  {{ formatPrice(totalContractPrice) }}
                </td>

                <td colspan="4" v-else>0.00</td>
              </tr>
            </tbody>
          </table>
        </b-card>
      </b-col>

      <b-col cols="12" xl="2" md="3" class="invoice-actions ">
        <b-card>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            v-b-toggle.sidebar-send-invoice
            variant="success"
            class="mb-75"
            block
          >
            Approve
          </b-button>
          <hr />

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="info"
            class="mb-75"
            block
          >
            Revise
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            v-b-toggle.sidebar-send-invoice
            variant="danger"
            class="mb-75"
            block
          >
            Cancel
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import {
  VBTooltip,
  BImg,
  BMediaAside,
  BContainer,
  BBadge,
  BOverlay,
  BRow,
  BCol,
  BCard,
  BCardBody,
  BTableLite,
  BCardText,
  BButton,
  BAlert,
  BLink,
  VBToggle,
  BFormCheckbox,
  BListGroup,
  BListGroupItem,
} from "bootstrap-vue";

import Ripple from "vue-ripple-directive";
import router from "@/router";
import Logo from "@core/layouts/components/QuoteLogo.vue";
import store from "@/store";
import settingsStoreModule from "../../settingsStoreModule";
import { ref, onUnmounted, reactive } from "@vue/composition-api";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import moment from "moment";
import axiosIns from "@/libs/axios";

export default {
  directives: {
    Ripple,
    "b-toggle": VBToggle,
    "b-tooltip": VBTooltip,
  },

  components: {
    VBTooltip,
    BImg,
    BMediaAside,
    BContainer,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,
    BFormCheckbox,
    BOverlay,
    BBadge,
    BListGroup,
    BListGroupItem,
    AppTimeline,
    AppTimelineItem,
    Logo,
  },

  setup() {
    const SETTINGS_APP_STORE_MODULE_NAME = "settings";
    // Register module
    if (!store.hasModule(SETTINGS_APP_STORE_MODULE_NAME))
      store.registerModule(SETTINGS_APP_STORE_MODULE_NAME, settingsStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SETTINGS_APP_STORE_MODULE_NAME))
        store.unregisterModule(SETTINGS_APP_STORE_MODULE_NAME);
    });
  },

  data() {
    return {
      where: router.currentRoute.name,
      //userRole: JSON.parse(localStorage.getItem("userData")).role,
      formShow: false,
      customerDetailsData: {
        quoteDate: null,
        firstName: null,
        lastName: null,
        email: null,
        organisation: null,
        phoneNumber: null,
        billingAdress: null,
        shippingAdress: null,
      },
      contractDetailsData: [
        {
          customerName: null,
          courseAbbreviation: null,
          deliveryOPCode: null,
          date: "1-1-2022",
          participant: null,
          price: null,
        },
      ],
      totalContractPrice: null,
      //baseURL: store.state.app.baseURL,
      //fileURL: store.state.app.fileURL,
    };
  },

  created() {},

  methods: {
    formatPrice(value, val) {
      if (value != null) {
        let val = (value / 1).toFixed(2).replace(",", ".");
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },

    dateFormat(val) {
      return moment(String(val)).format("DD-MM-YYYY HH:mm");
    },
  },

  computed: {},
};
</script>

<style>
.textlongvalue {
  line-height: 1.84;
}

.title {
  font-size: 12px;
}

.textproduct {
  font-size: 12px;
}

.noborderright {
  border-right: hidden !important;
}

.valueinput {
  font-size: 16px;
}
</style>
